import React, { useState } from 'react'

import UserDialogPreview from '../UserDialogPreview/UserDialogPreview'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { Chevron } from 'uiKit/icons/Icons'
import { isStringEmpty } from 'helpers/isStringEmpty'
import { SupportUserType } from 'models/SupportUserType'

import * as S from './DialogsSection.styles'

interface Props {
  title: string
  users: SupportUserType[]
  search: string
  loading: boolean
  loadMoreUsers?: any
  usersTotal: number
}

export const DialogsSection: React.FC<Props> = ({ title, users, search, loadMoreUsers, loading, usersTotal}) => {
  const [collapsed, setCollapsed] = useState(true)
  const [showButton, setShowButton] = useState(true)

  const filterUser = user => {
    if (user) {
      return (
        isStringEmpty(search) ||
        user?.firstName?.toLowerCase()?.includes(search?.toLowerCase()) ||
        user?.lastName?.toLowerCase()?.includes(search?.toLowerCase())
      )
    }
    return false
  }

  const handleLoad = () => {
    loadMoreUsers().then(res => {
      if (res?.length < 20) setShowButton(false)
    })
  }

  return (
    <>
      <S.Title onClick={() => setCollapsed(!collapsed)}>
        {title}
        {users && !users?.length ? (
            <span>&nbsp;0</span>
        ) : (
            <span>
            {` ${users.length}/${usersTotal}`}
              <Chevron rotate={collapsed} />
          </span>
        )}
      </S.Title>

      {collapsed && (
        <>
          {users && users?.filter(filterUser)?.map(user => <UserDialogPreview user={user} key={user.chatId} />)}
          {users && users?.length >= 20 && loadMoreUsers && !loading && showButton && (
            <S.LoadMoreButtonWrapper>
              <S.LoadMoreButton onClick={handleLoad}>Load more chats</S.LoadMoreButton>
            </S.LoadMoreButtonWrapper>
          )}
          {loading && <LoaderSmall showLoader={true} />}
        </>
      )}
    </>
  )
}

import React, { useMemo, useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import Heading from '../../../../uiKit/texts/Heading'
import Input from '../../../../uiKit/inputs/Input'
import LaguageSelector from '../../../../uiKit/LaguageSelector/LaguageSelector'
import Paragraph from '../../../../uiKit/texts/Paragraph'
import Checkbox from '../../../../uiKit/Checkbox'
import StartWidgetScreen from '../StartWidgetScreen'
import { deepCopyFunction } from '../../../../helpers/deepCopyFunction'
import { updateWidget } from '../../api/widget'
import { SPUN_PEARL_GREY } from '../../../../constants/colors'
import { setErrors } from '../../../../helpers/setErrors/setErrors'
import { deepCompare } from '../../../../helpers/deepCompare'

import classes from './styles.module.scss'
import {DefaultSettingsMapping, WidgetSettingsType} from '../../../../models/WidgetSettingsTypes'

interface Props {
  match: any
  widgetSettings: WidgetSettingsType
  widgetDefaultSettings: DefaultSettingsMapping
}

const WelcomeScreenTab: React.FC<Props> = ({ match, widgetSettings, widgetDefaultSettings }) => {
  const [selectedLanguage, setSelectedLanguages] = useState({ label: 'English', value: 'en' })
  const [title, setTitle] = useState(widgetSettings?.languageConfigs[selectedLanguage.value]?.welcomeTitle || '')
  const [greetingText, setGreetingText] = useState(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.welcomeSubtitle || '',
  )
  const [startLabel, setStartLabel] = useState(
    widgetSettings?.languageConfigs[selectedLanguage.value]?.startLabel || '',
  )
  const [errorsTitle, setErrorsTitle] = useState<string[]>([])
  const [errorsGreetingText, setErrorsGreetingText] = useState<string[]>([])
  const [errorsStartLabel, setErrorsStartLabel] = useState<string[]>([])

  const widgetCopy = useMemo(() => deepCopyFunction(widgetSettings), [widgetSettings])
  const isSelectedField =
    widgetSettings?.languageConfigs[selectedLanguage.value]?.isLoginNameInputEnabled ||
    widgetSettings?.languageConfigs[selectedLanguage.value]?.isLoginEmailInputEnabled

  const handleUpdateWidgetSettings = (value: string | boolean, field: string) => {
    widgetCopy.languageConfigs[selectedLanguage.value][field] = value
    if (!deepCompare(widgetCopy, widgetSettings)) {
      updateWidget(match.params.botId, widgetCopy)
    }
  }

  const handleUpdateTitle = (value: string) => {
    const isValid = validateField(value, setErrorsTitle, 40)

    if (isValid) {
      handleUpdateWidgetSettings(value, 'welcomeTitle')
    }
  }

  const handleUpdateGreetingText = (value: string) => {
    const isValid = validateField(value, setErrorsGreetingText, 100)

    if (isValid) {
      handleUpdateWidgetSettings(value, 'welcomeSubtitle')
    }
  }

  const handleUpdateStartLabel = (value: string) => {
    const isValid = validateField(value, setErrorsStartLabel, 100)

    if (isValid) {
      handleUpdateWidgetSettings(value, 'startLabel')
    }
  }

  const handleChangeLanguage = (language) => {
    setSelectedLanguages(language)
    setStartLabel(widgetSettings?.languageConfigs[language.value]?.startLabel)
    setTitle(widgetSettings?.languageConfigs[language.value]?.welcomeTitle)
    setGreetingText(widgetSettings?.languageConfigs[language.value]?.welcomeSubtitle)
  }

  const handleChangeTitle = (event) => {
    validateField(event.target.value, setErrorsTitle, 40)
    setTitle(event.target.value)
  }

  const handleChangeSubtitle = (event) => {
    validateField(event.target.value, setErrorsGreetingText, 100)
    setGreetingText(event.target.value)
  }

  const handleChangeStartLabel = (event) => {
    validateField(event.target.value, setErrorsStartLabel, 100)
    setStartLabel(event.target.value)
  }

  const validateField = (value: string, callback: (a: any) => void, maxLength: number) => {
    const errors = setErrors(value, maxLength)
    callback(errors)

    return !errors.length
  }

  return (
    <div>
      <div className={classes.header}>
        <Heading>Welcome Screen</Heading>
        <LaguageSelector onChange={handleChangeLanguage} value={selectedLanguage} />
      </div>

      <Paragraph style={{ color: SPUN_PEARL_GREY }}>
        Add and customize input fields that will be shown at the first widget screen
      </Paragraph>

      <div className={classes.body}>
        <div className={classes.form}>
          <Paragraph style={{ marginTop: 0 }}>Choose the input fields to start a chat</Paragraph>
          <Checkbox
            label="Name input"
            checked={!!widgetSettings?.languageConfigs[selectedLanguage.value]?.isLoginNameInputEnabled}
            onChange={(value: boolean) => handleUpdateWidgetSettings(value, 'isLoginNameInputEnabled')}
          />
          <Checkbox
            label="Email input"
            checked={!!widgetSettings?.languageConfigs[selectedLanguage.value]?.isLoginEmailInputEnabled}
            onChange={(value: boolean) => handleUpdateWidgetSettings(value, 'isLoginEmailInputEnabled')}
          />
          {isSelectedField && (
            <>
              <Input
                title="Welcome title"
                placeholder="title"
                value={title || ''}
                onChange={handleChangeTitle}
                handleEnterPress={() => handleUpdateTitle(title)}
                onBlur={() => handleUpdateTitle(title)}
                error={!!errorsTitle.length}
                errorMessage={errorsTitle[0]}
              />
              <Input
                title="Welcome subtitle"
                placeholder="subtitle"
                value={greetingText || ''}
                onChange={handleChangeSubtitle}
                handleEnterPress={() => handleUpdateGreetingText(greetingText)}
                onBlur={() => handleUpdateGreetingText(greetingText)}
                error={!!errorsGreetingText.length}
                errorMessage={errorsGreetingText[0]}
              />
              <Input
                title="Start label"
                placeholder="Start label"
                value={startLabel || ''}
                onChange={handleChangeStartLabel}
                handleEnterPress={() => handleUpdateStartLabel(startLabel)}
                onBlur={() => handleUpdateStartLabel(startLabel)}
                error={!!errorsStartLabel.length}
                errorMessage={errorsStartLabel[0]}
              />
            </>
          )}
        </div>
        {isSelectedField && widgetDefaultSettings && (
          <StartWidgetScreen languageConfigs={widgetSettings.languageConfigs[selectedLanguage.value]}
            defaultSettings={widgetDefaultSettings[selectedLanguage.value]}/>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state: {
  widgetSettings: WidgetSettingsType
  widgetDefaultSettings: DefaultSettingsMapping
}) => ({
  widgetSettings: state.widgetSettings,
  widgetDefaultSettings: state.widgetDefaultSettings,
})

export default withRouter(connect(mapStateToProps)(WelcomeScreenTab))

export const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  button: {
    padding: 5,
    margin: '0 12px 0 0',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none',
    fontFamily: "'Lato', sans-serif",
    color: '#354052',
    minHeight: 0,
  },
  paper: {
    marginRight: 0,
  },
  popper: {
    right: 'auto !important',
    left: 'calc(100% - 30px) !important',
    top: '100% !important',
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: '8px 12px',
    '& a': {
      color: '#354052',
      fontSize: 14,
      lineHeight: '20px',

      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& button': {
      color: '#354052',
      fontSize: 14,
      lineHeight: '20px',
      border: 'none',
      background: 'transparent',
      textAlign: 'left',
      padding: 0,
    },
  },
})

import styled from 'styled-components'
import { COMET_GREY, HAWKES_BLUE, PERMISSION_RED } from 'constants/colors'

const Container = styled.div<{ index: number }>`
  width: 100%;
  padding: 10px 24px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  background-color: ${({ index }) => (index % 2 == 0 ? HAWKES_BLUE : 'none')};
`

const Text = styled.p`
  margin: 0;
  color: ${COMET_GREY};
  font-size: 14px;
`

const DeleteButton = styled.div`
  font-size: 14px;
  cursor: pointer;
  color: ${PERMISSION_RED};
`

export { Container, Text, DeleteButton }

import React from 'react'

export const TabPanel = props => (
  <div
    role="tabpanel"
    hidden={props.value !== props.index}
    id={`scrollable-prevent-tabpanel-${props.index}`}
    aria-labelledby={`scrollable-prevent-tab-${props.index}`}
    {...props}
  />
)

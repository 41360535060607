import React, { useState } from 'react'
import { Tabs, Tab } from '@material-ui/core'
import { useForm } from 'react-hook-form/dist/index.ie11'

import { TabPanel } from '../../../../../uiKit/TabPanel'

import SubmitButton from '../../../../../uiKit/buttons/SubmitButton'

import { EditUserAdditional } from '../EditUserAdditional'
import { EditUserGeneral } from '../EditUserGeneral'
import { EditUserLanguages } from '../EditUserLanguages'
import DeleteAccess from '../DeleteAccess'
import { getSupportRequests } from '../../../../support/api/support'
import { postBotAccessor } from '../../../api/settings'

const tabsLabel = ['General', 'Languages', 'Additional']

export const EditUser = props => {
  const { classes } = props
  const [activeTab, setActiveTab] = useState(0)
  const { control, handleSubmit, getValues, register } = useForm({
    defaultValues: props.user,
    shouldUnregister: false,
  })

  const onSubmit = data => {
      postBotAccessor(props.botId, data).then(() => {
        getSupportRequests(props.botId, props.adminUser?.id).then()
        props.closeEditUserModal()
      })
  }

  const handleChange = (event, newValue) => {
    setActiveTab(newValue)
  }

  const handleCancel = e => {
    e.preventDefault()
    props.closeEditUserModal()
  }
  const removeAndCLose = (id) => {
    props.removeAccess(id)
    props.closeEditUserModal()
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        classes={{
          root: classes.tabsRoot,
        }}
        indicatorColor="primary">
        {tabsLabel.map(tab => (
          <Tab
            disableRipple
            key={tab}
            label={tab}
            classes={{
              root: classes.tabRoot,
              labelContainer: classes.tabContainer,
              selected: classes.tabSelected,
            }}
          />
        ))}
      </Tabs>
      <TabPanel index={0} value={activeTab} className={classes.tabPanel}>
        <EditUserAdditional control={control} user={props.user} />
      </TabPanel>
      <TabPanel index={1} value={activeTab} className={classes.tabPanel}>
        <EditUserLanguages botId={props.botId} getValues={getValues} control={control} register={register} />
      </TabPanel>
      <TabPanel index={2} value={activeTab} className={classes.tabPanel}>
        <EditUserGeneral user={props.user} control={control} />
      </TabPanel>
      <div className={classes.buttons}>
        {props.user.adminId !== props.adminUser.id && props.user.isInvited == false && (
          <DeleteAccess email={props.user.email} id={props.user.id} submit={removeAndCLose} />
        )}
        <SubmitButton
          empty
          title="Cancel"
          onClick={handleCancel}
          styles={{ borderRadius: 4, marginRight: 8, marginLeft: 'auto', fontSize: 16 }}
        />
        <SubmitButton title="Save" styles={{ borderRadius: 4, fontSize: 16 }} />
      </div>
    </form>
  )
}

import React from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import uuid from 'uuid'
import withStyles from '@material-ui/core/styles/withStyles'
import TableRow from '../../../../uiKit/table/TableRow'
import TableCell from '../../../../uiKit/table/TableCell'
import Table from '../../../../uiKit/table/Table'
import Button from '@material-ui/core/Button'
import Modal from '../../../../uiKit/Modal'
import {SearchIcon} from '../../../../uiKit/icons/Icons'
import {FormControlLabel} from '@material-ui/core'

import {isWindowSizeDownLg} from '../../../../helpers/isWindowSizeDownLg'

import {UserMenu} from './UserMenu'

import {EditUser} from './EditUser'
import Switch from '@material-ui/core/Switch'
import ShareModal from '../../../home/components/ShareModal'
import CheckIcon from '../../../../uiKit/icons/CheckIcon'
import {
  loadBotAccessors, removeBotAccessor, updateBotAccessorRole, updateBotAccessorSupportRequestNotifying,
} from '../../api/settings'
import {ROLE} from '../../../../security'
import Heading from '../../../../uiKit/texts/Heading'

const supportRequestNotifyTooltipText = 'Send email notification about new customer' +
    ' support request and assigned to you request.'
const styles = theme => ({
  inputHolder: {
    border: '1px solid #C7CAD6',
    width: 'max-content',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    marginRight: 10,
    padding: 10,
    maxHeight: 45,
    minWidth: 340,
  }, input: {
    width: '100%',
    padding: '10px',
    border: 'none',
    outline: 'none',
    borderRadius: '5px',
    fontSize: '14px',
    lineHeight: '19px',
    backgroundColor: 'white',
  }, submitButton: {
    background: 'linear-gradient(285.2deg, #5A98F7 -35.9%, #1658F3 61.17%)',
    boxShadow: '0px 3px 9px rgba(19, 69, 186, 0.206267)',
    borderRadius: 10,
    height: 45,
    outline: 'none',
  }, submitButtonText: {
    color: 'white', margin: 0, fontWeight: 500, fontSize: 16, textTransform: 'none', fontFamily: 'Lato, sans-serif',
  }, paper: {
    padding: '24px',
  }, userPaper: {
    padding: '24px', maxWidth: 360, width: '100%',
  }, tableCellData: {
    margin: 'auto 0', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap',

    '& > span': {
      display: 'block', color: 'rgba(53, 64, 82, 0.5)', fontSize: 12, lineHeight: '16px',
    },
  },
})

const tableTitles = [{
  title: 'Full name', style: {paddingRight: 16, flex: '0 0 15%', maxWidth: 'none'},
}, {
  title: 'Email', style: {paddingRight: 16, flex: '0 0 20%', maxWidth: 'none'},
}, {
  title: 'Salesforce ID', style: {paddingRight: 16, flex: '0 0 15%', maxWidth: 'none'},
}, {
  title: 'Role', style: {paddingRight: 16, flex: '0 0 10%', maxWidth: 'none'},
}, {
  title: 'Languages', style: {paddingRight: 16, flex: '0 0 20%', maxWidth: 'none'},
}, {
  title: 'Send notifications',
  style: {flex: '0 0 18%', maxWidth: 'none'},
  tooltipText: supportRequestNotifyTooltipText,
}, {
  style: {flex: '0 0 2%', maxWidth: 'none'},
}]

const showLanguageLength = isWindowSizeDownLg ? 2 : 3

const getFirstFourLanguages = languages => languages
  ?.filter((language, index) => index < showLanguageLength)
  .map(language => language.fullName)
  .join(', ')

const getAnotherLanguages = languages =>
  languages?.length > showLanguageLength && `and ${languages.length - showLanguageLength} more`

class Users extends React.Component {
    state = {
      open: false, isOpenEditUser: false, searchUser: '', editUser: null,
    }
    removeAccess = id => {
      removeBotAccessor(this.props.match.params.botId, id)
    }
    closeShareModal = () => {
      this.setState({open: false})
    }
    closeEditUserModal = () => {
      this.setState({isOpenEditUser: false})
      loadBotAccessors(this.props.match.params.botId)
    }
    openEditUserModal = user => {
      this.setState({
        isOpenEditUser: true, editUser: user,
      })
    }

    componentDidMount() {
      loadBotAccessors(this.props.match.params.botId)
    }

    handleSearchUser(value) {
      this.setState({searchUser: value})
    }

    updateRole(id, inviteAccessId, newRole) {
      updateBotAccessorRole(this.props.match.params.botId, id, inviteAccessId, newRole)
    }

    updateSupportNotifying(id, supportRequestNotify) {
      updateBotAccessorSupportRequestNotifying(this.props.match.params.botId, id, supportRequestNotify)
    }

    render() {
      const botAccessors = this.props.botAccessors
      const {classes} = this.props
      return (<div>
        <Heading>Users</Heading>

        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20}}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <div className={classes.inputHolder}>
              <input
                className={classes.input}
                placeholder="Search admin panel users by name"
                onChange={event => this.handleSearchUser(event.target.value)}
              />
              <SearchIcon width="20" height="20" color="#D8D8D8"/>
            </div>
          </div>
          <Button className={classes.submitButton} onClick={() => this.setState({open: true})}>
            <p className={classes.submitButtonText}>Invite new user</p>
          </Button>
        </div>
        <Table titles={tableTitles}>
          {!(botAccessors && botAccessors.length) ? (<div/>) : (botAccessors
            .sort(ba => (!ba.isInvited ? -1 : 1))
            .filter(ba => ba.email.toUpperCase().indexOf(this.state.searchUser.toUpperCase()) !== -1)
            .map((accessor, index) => (<TableRow key={uuid()} index={index}>
              <TableCell styles={{flex: '0 0 15%', width: '15%', paddingRight: 16}}>
                {accessor.isInvited ? (
                  <p title={accessor.name} className={classes.tableCellData}>
                    <i>{accessor.name}</i>
                  </p>) : (<p title={accessor.name} className={classes.tableCellData}>
                  {accessor.name}
                </p>)}
              </TableCell>
              <TableCell styles={{paddingRight: 16, flex: '0 0 20%', width: '20%'}}>
                <p title={accessor.email} className={classes.tableCellData}>
                  {accessor.email}
                </p>
              </TableCell>
              <TableCell
                styles={{paddingRight: 16, flex: '0 0 15%', width: '15%', maxWidth: 'none'}}>
                <p title={accessor.salesforceId} style={{margin: '0 auto'}}
                  className={classes.tableCellData}>
                  {accessor.salesforceId && <CheckIcon/>}
                </p>
              </TableCell>
              <TableCell
                styles={{paddingRight: 16, flex: '0 0 10%', width: '10%', maxWidth: 'none'}}>
                <p title={accessor.role === ROLE.SUPPORT ? 'Support' : 'Admin'}
                  className={classes.tableCellData}>
                  {accessor.role === ROLE.SUPPORT ? 'Support' : 'Admin'}
                </p>
              </TableCell>
              <TableCell
                styles={{paddingRight: 16, flex: '0 0 20%', width: '20%', maxWidth: 'none'}}>
                <p className={classes.tableCellData}>
                  {getFirstFourLanguages(accessor.languages)}
                  <span>{getAnotherLanguages(accessor.languages)}</span>
                </p>
              </TableCell>
              <TableCell styles={{flex: '0 0 18%', width: '18%', height: 48, maxWidth: 'none'}}>
                <div style={{margin: 'auto'}}>
                  {accessor.isInvited == false ? (<div className={classes.tableCellData}>
                    <FormControlLabel
                      className="checkbox"
                      control={<Switch
                        size="small"
                        color="primary"
                        checked={accessor.supportRequestNotify}
                        onChange={(event, checked) => this.updateSupportNotifying(accessor.id, checked)}
                      />}
                    />
                  </div>) : null}
                </div>
              </TableCell>
              <TableCell styles={{flex: '0 0 2%', width: '2%', height: 24, maxWidth: 'none'}}>
                <UserMenu
                  accessor={accessor}
                  openEditUserModal={this.openEditUserModal}
                  adminUser={this.props.adminUser}
                  removeAccess={this.removeAccess}
                />
              </TableCell>
            </TableRow>)))}
        </Table>
        <Modal
          classes={{paper: classes.paper}}
          open={this.state.open}
          onClose={this.closeShareModal}
          title="Share a chatbot">
          <ShareModal bot={{id: this.props.match.params.botId}} closeModal={this.closeShareModal}/>
        </Modal>
        <Modal
          classes={{paper: classes.userPaper}}
          open={this.state.isOpenEditUser}
          onClose={this.closeEditUserModal}
          title="Edit user"
          customStyle={{height: '100%', maxHeight: '85%'}}>
          <EditUser
            adminUser={this.props.adminUser}
            botId={this.props.match.params.botId}
            user={this.state.editUser}
            closeEditUserModal={this.closeEditUserModal}
            removeAccess={this.removeAccess}
          />
        </Modal>
      </div>)
    }
}

const mapStateToProps = state => ({
  botAccessors: state.botAccessors, adminUser: state.adminUser,
})

export default withRouter(withStyles(styles, {withTheme: true})(connect(mapStateToProps)(Users)))

import {
    SAVE_ALL_SUPPORT_USERS,
    SAVE_ASSIGNED_SUPPORT_USERS,
    SAVE_ASSIGNED_TO_SUPPORT_USERS,
    SAVE_CLOSED_SUPPORT_USERS,
    SAVE_EXPIRED_SUPPORT_USERS,
    SAVE_MORE_ALL_SUPPORT_USERS,
    SAVE_MORE_ASSIGNED_SUPPORT_USERS,
    SAVE_MORE_ASSIGNED_TO_SUPPORT_USERS,
    SAVE_MORE_CLOSED_SUPPORT_USERS,
    SAVE_MORE_EXPIRED_SUPPORT_USERS,
    SAVE_MORE_NEW_SUPPORT_USERS,
    SAVE_MORE_SUPPORT_USERS,
    SAVE_NEW_SUPPORT_USERS,
    SAVE_SUPPORT_USERS,
    UPDATE_SUPPORT_USER_LIST,
    UPDATE_USER_UNREAD_MESSAGE,
} from '../actions/users'

import {SUPPORT_STATUSES} from 'constants/suportStatuses'

const initialState = {
    assignedToMeUsers: [],
    newRequestsUsers: [],
    expiredRequestsUsers: [],
    assignedRequestsUsers: [],
    allUsers: [],
    closedRequestsUsers: [],
}

const _filterAssignedToMeUsers = payload => {
    return payload.supportUsers.filter(user => {
        return (
            user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
            user?.supportRequest?.support?.adminId === payload.adminUserId
        )
    })
}

const _filterNewRequestsUsers = payload => {
    return payload.supportUsers.filter(user => {
        return user?.supportRequest?.status === SUPPORT_STATUSES.OPEN ||
            user?.supportRequest?.status === SUPPORT_STATUSES.PENDING
    })
}

const _filterExpiredRequestsUsers = payload => {
    return payload.supportUsers.filter(user => {
        return user?.supportRequest?.status === SUPPORT_STATUSES.EXPIRED
    })
}

const _filterAssignedRequestsUsers = payload => {
    return payload.supportUsers.filter(user => {
        return (
            user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
            user.supportRequest?.support?.adminId !== payload.adminUserId
        )
    })
}

const _filterAllUsers = payload => {
    return payload.supportUsers.filter(user => {
        return (
            user.supportRequest?.status !== SUPPORT_STATUSES.ASSIGNED &&
            user.supportRequest?.status !== SUPPORT_STATUSES.EXPIRED &&
            user.supportRequest?.status !== SUPPORT_STATUSES.PENDING &&
            user.supportRequest?.status !== SUPPORT_STATUSES.OPEN
        )
    })
}

const _updateUserAssignedToMe = (state, payload, allUsers) => {
    const rule =
        payload.user.supportRequest?.status !== SUPPORT_STATUSES.ASSIGNED ||
        payload.user.supportRequest?.adminId !== payload.adminUserId

    return _updateUser(state, payload, rule, allUsers)
}

const _updateUserExpired = (state, payload, allUsers) => {
    const rule = payload.user.supportRequest?.status !== SUPPORT_STATUSES.EXPIRED

    return _updateUser(state, payload, rule, allUsers)
}

const _updateUserAssigned = (state, payload, allUsers) => {
    const rule =
        payload.user.supportRequest?.status !== SUPPORT_STATUSES.ASSIGNED ||
        (payload.user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
            payload.user.supportRequest?.adminId === payload.adminUserId)

    return _updateUser(state, payload, rule, allUsers)
}

const _updateUserNew = (state, payload, allUsers) => {
    const rule =
        payload.user.supportRequest?.status !== SUPPORT_STATUSES.OPEN &&
        payload.user.supportRequest?.status !== SUPPORT_STATUSES.PENDING

    return _updateUser(state, payload, rule, allUsers)
}

const _updateAllUser = (state, payload, allUsers) => {
    const rule =
        payload.user.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED ||
        payload.user.supportRequest?.status === SUPPORT_STATUSES.EXPIRED ||
        payload.user.supportRequest?.status === SUPPORT_STATUSES.PENDING ||
        payload.user.supportRequest?.status === SUPPORT_STATUSES.OPEN

    return _updateUser(state, payload, rule, allUsers)
}

const _updateUser = (state, payload, rule, allUsers) => {
    const isNewUser = !state.find(user => user?.userId === payload.user?.userId)

    if (rule && isNewUser) return state

    if (rule && !isNewUser) return state.filter(user => user.userId !== payload.user.userId)

    const transferredUser = Object.assign(
        {},
        allUsers?.find(user => user?.userId === payload.user?.userId),
        payload.user,
    )

    return isNewUser && transferredUser?.firstName
        ? [transferredUser, ...state]
        : state.map(user => {
            if (user.userId === payload.user?.userId) {
                user = Object.assign({}, user, payload.user)
            }
            return user
        })
}

const _updateUserUnreadMessages = (state, payload) => {
    return state.map(user => {
        if (user?.userId === payload.user?.userId && user?.unreadMessagesCount) {
            payload.user.unreadMessage = false
            payload.user.unreadMessagesCount = 0
            payload.user.lastMessage = user.lastMessage
            payload.user.messagePreview = user.messagePreview
            return payload.user
        }
        return user
    })
}

const supportUsersNew = (state = initialState, {type, payload}) => {
    const usersList = [
        ...state.assignedToMeUsers,
        ...state.newRequestsUsers,
        ...state.expiredRequestsUsers,
        ...state.assignedRequestsUsers,
        ...state.allUsers,
        ...state.closedRequestsUsers,
    ]
    switch (type) {
        case SAVE_SUPPORT_USERS:
            return {
                ...state,
                assignedToMeUsers: _filterAssignedToMeUsers(payload),
                newRequestsUsers: _filterNewRequestsUsers(payload),
                expiredRequestsUsers: _filterExpiredRequestsUsers(payload),
                assignedRequestsUsers: _filterAssignedRequestsUsers(payload),
                allUsers: _filterAllUsers(payload),
            }

        case SAVE_MORE_SUPPORT_USERS:
            return {
                ...state,
                assignedToMeUsers: [...state.assignedToMeUsers, ..._filterAssignedToMeUsers(payload)],
                newRequestsUsers: [...state.newRequestsUsers, ..._filterNewRequestsUsers(payload)],
                expiredRequestsUsers: [...state.expiredRequestsUsers, ..._filterExpiredRequestsUsers(payload)],
                assignedRequestsUsers: [...state.assignedRequestsUsers, ..._filterAssignedRequestsUsers(payload)],
                allUsers: [...state.allUsers, ..._filterAllUsers(payload)],
            }
        case UPDATE_SUPPORT_USER_LIST:
            return {
                ...state,
                assignedToMeUsers: _updateUserAssignedToMe(state.assignedToMeUsers, payload, usersList),
                expiredRequestsUsers: _updateUserExpired(state.expiredRequestsUsers, payload, usersList),
                assignedRequestsUsers: _updateUserAssigned(state.assignedRequestsUsers, payload, usersList),
                newRequestsUsers: _updateUserNew(state.newRequestsUsers, payload, usersList),
                allUsers: _updateAllUser(state.allUsers, payload, usersList),
            }

        case UPDATE_USER_UNREAD_MESSAGE:
            return {
                ...state,
                assignedToMeUsers: _updateUserUnreadMessages(state.assignedToMeUsers, payload),
                expiredRequestsUsers: _updateUserUnreadMessages(state.expiredRequestsUsers, payload),
                assignedRequestsUsers: _updateUserUnreadMessages(state.assignedRequestsUsers, payload),
                allUsers: _updateUserUnreadMessages(state.allUsers, payload),
                newRequestsUsers: _updateUserUnreadMessages(state.newRequestsUsers, payload),
            }

        case SAVE_CLOSED_SUPPORT_USERS:
            return {
                ...state,
                closedRequestsUsers: [...payload.supportUsers],
            }

        case SAVE_MORE_CLOSED_SUPPORT_USERS:
            return {
                ...state,
                closedRequestsUsers: [...state.closedRequestsUsers, ...payload.supportUsers],
            }

        case SAVE_NEW_SUPPORT_USERS:
            return {
                ...state,
                newRequestsUsers: [...payload.supportUsers],
            }

        case SAVE_EXPIRED_SUPPORT_USERS:
            return {
                ...state,
                expiredRequestsUsers: [...payload.supportUsers],
            }

        case SAVE_ASSIGNED_SUPPORT_USERS:
            return {
                ...state,
                assignedRequestsUsers: [
                    ...payload.supportUsers
                ],
            }

        case SAVE_ASSIGNED_TO_SUPPORT_USERS:
            return {
                ...state,
                assignedToMeUsers: [...payload.supportUsers],
            }

        case SAVE_ALL_SUPPORT_USERS:
            return {
                ...state,
                allUsers: [...payload.supportUsers],
            }

        case SAVE_MORE_ALL_SUPPORT_USERS:
            return {
                ...state,
                allUsers: [...state.allUsers, ...payload.supportUsers],
            }

        case SAVE_MORE_EXPIRED_SUPPORT_USERS:
            return {
                ...state,
                expiredRequestsUsers: [...state.expiredRequestsUsers, ...payload.supportUsers],
            }

        case SAVE_MORE_ASSIGNED_TO_SUPPORT_USERS:
            return {
                ...state,
                assignedToMeUsers: [...state.assignedToMeUsers, ...payload.supportUsers],
            }

        case SAVE_MORE_NEW_SUPPORT_USERS:
            return {
                ...state,
                newRequestsUsers: [...state.newRequestsUsers, ...payload.supportUsers],
            }

        case SAVE_MORE_ASSIGNED_SUPPORT_USERS:
            return {
                ...state,
                assignedRequestsUsers: [
                    ...state.assignedRequestsUsers,
                    ...payload.supportUsers
                ],
            }

        default:
            return state
    }
}

export default supportUsersNew

import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

const styles = () => ({
  tableCell: {
    paddingRight: '16px',
    flex: '0 0 15%',
    width: '16%',
    '&:last-child': {
      maxWidth: 50,
    },
    '&:nth-last-child(2)': {
      maxWidth: 150,
    },
  },
})

class TableCell extends React.Component {
  render() {
    const { classes, style } = this.props
    return (
      <div className={classes.tableCell} style={style}>
        {this.props.children}
      </div>
    )
  }
}

export default withStyles(styles)(TableCell)

import styled from 'styled-components'
import { SELAGO_GRAY } from 'constants/colors'

const Container = styled.div`
  height: 100%;
`

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const StatusWrap = styled.div`
  display: flex;
  gap: 40px;
`

const FormWrap = styled.div`
  position: relative;
`

const DisableScreen = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0.3;
  background-color: ${SELAGO_GRAY};
`

export { Container, Wrap, StatusWrap, FormWrap, DisableScreen }

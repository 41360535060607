import React, { useState, useEffect } from 'react'
import { useFieldArray } from 'react-hook-form/dist/index.ie11'
import Switch from '@material-ui/core/Switch'
import { getBotLanguages } from '../../../api/settings'

export const EditUserLanguages = props => {
  const { classes } = props
  const [botLanguages, setBotLanguages] = useState()
  const { fields, remove, append } = useFieldArray({
    control: props.control,
    name: 'languages',
  })

  useEffect(() => {
    getBotLanguages(props.botId).then(response => setBotLanguages(response.activeLanguages))
  }, [])

  const onChange = (e, language) => {
    e.target.checked ? append({ ...language }) : remove(fields.findIndex(item => item.id === language.id))
  }

  return (
    <div>
      <p className={classes.styleMainText}>Configure user access to chatbot languages and live chats</p>

      {botLanguages &&
        botLanguages.map(item => {
          const isUserLanguage = fields.length > 0 && !!fields.find(language => language.id === item.id)

          return (
            <div className={classes.styleCheckbox} key={item.id}>
              <p className={classes.styleParagraph}>{item.fullName}</p>
              <Switch
                classes={{ switchBase: classes.switchBase }}
                size="small"
                color="primary"
                checked={isUserLanguage}
                onChange={e => onChange(e, item)}
              />
            </div>
          )
        })}
      {fields.length === 0 && <p className={classes.styleError}>Please select at least 1 language</p>}
    </div>
  )
}

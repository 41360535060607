import React, { useState } from 'react'
import Input from 'uiKit/inputs/Input'
import { urlValidation } from 'helpers/urlValidation'

import * as S from './WebsiteURLForm.style'
import { EnterArrow } from 'uiKit/icons/EnterArrow'

interface Props {
  urls: string[]
  onClick: (url: string) => void
}

export const WebsiteURLForm: React.FC<Props> = ({ onClick, urls }) => {
  const [website, setWebsite] = useState('')
  const [error, setError] = useState('')

  const handleChange = e => {
    const isUnique = !urls.some(url => url === e.target.value)
    const isValid = urlValidation(e.target.value) && e.target.value.startsWith('https://')

    if (!isValid) {
      setError('Please enter valid URL')
    } else if (!isUnique) {
      setError('This URL is already added')
    } else {
      setError('')
    }

    setWebsite(e.target.value)
  }

  const handleEnter = () => {
    if (!error && website) {
      onClick(website)
      setWebsite('')
    }
  }

  return (
    <S.Container>
      <S.Title>Website URL</S.Title>
      <S.Form>
        <Input
          placeholder="+Add website URL"
          value={website}
          onChange={handleChange}
          error={error}
          errorMessage={error}
          handleEnterPress={handleEnter}
        />
        <S.Icon>
          <EnterArrow/>
        </S.Icon>
      </S.Form>
    </S.Container>
  )
}

import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { BASE_URL } from '../../../configs'
import { saveSupportRequests } from '../actions/supportRequest'

export function getSupportRequests(botId, adminId) {
  return fetch(`${BASE_URL}/${botId}/extended/support/count?adminId=${adminId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveSupportRequests(json)
    })
    .catch(function(error) {
      alertError('Sorry but something going wrong please ping support')
    })
}

import React from 'react'
import ReactTooltip from 'react-tooltip'

import { DoneIcon } from 'uiKit/icons/Icons'
import { ArrowRotate } from 'uiKit/icons/ArrowRotate'

import { CREATING, PENDING, READY } from '../../../constants/trainingStatus'
import { RIBBON_BLUE } from 'constants/colors'

import * as S from './TrainingStatus.style'

interface Props {
  status: string
}

export const TrainingStatus: React.FC<Props> = ({ status }) => {
  const getStatus = status => {
    if ([PENDING, CREATING].includes(status)) return 'Bot is training'
    if (status === READY) return 'Bot is trained'
    return ''
  }

  const getIcon = status => {
    if ([PENDING, CREATING].includes(status)) return <ArrowRotate />
    if (status === READY) return <DoneIcon color={RIBBON_BLUE} />
    return ''
  }

  return (
    <>
      <S.Container data-tip data-for="tooltip-pending">
        {getIcon(status)}
        <S.Text>{getStatus(status)}</S.Text>
      </S.Container>
      {[PENDING, CREATING].includes(status) && (
        <ReactTooltip place="top" effect="solid" id="tooltip-pending">
          Training may take some time
        </ReactTooltip>
      )}
    </>
  )
}

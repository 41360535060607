import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { getUrlEnd } from '../helpers/getUrlEnd'
import { isOpenBot } from '../helpers/isOpenBot'
import { isSupportTab } from '../helpers/isSupportTab'

import { updateSupportUserList } from '../tabs/support/actions/users'
import {
  saveMessage,
  updateSupportMessageStatus,
  updateActiveUser,
} from '../tabs/support/actions/activeUser'
import { getSupportRequests } from '../tabs/support/api/support'
import NotificationService from '../services/notificationService'
import { DELIVERED } from '../constants/statuses'
import WebSocketClient from '../services/WebSocketService'
import { SUPPORT_STATUSES } from '../constants/suportStatuses'
import { CHAT_STATUS } from '../constants/chatStatus'
import { saveUnassignedCount, saveUnreadAssignedToCount } from '../tabs/support/actions/supportRequest'

export const SharedWebsocketContext = React.createContext()

class WebSockets extends React.Component {
  constructor(props) {
    super(props)

    this.websocketClient = new WebSocketClient()
    this.websocketClient.configureMessageTopic(this.handleReceiveMessages)
    this.websocketClient.configureChatStatusTopic(this.handleReceiveChatStatuses)
    this.websocketClient.configureUnassignedCountTopic(this.handleReceiveUnassignedCount)
    this.websocketClient.configureUnreadAssignedToCountTopic(this.handleReceiveUnreadAssignedToCount)
  }

  componentDidMount() {
    this.websocketClient.connect(this.props.botId, this.props.adminUser.id)
  }

  componentWillUnmount() {
    this.websocketClient.disconnect()
  }

  supportedLanguage = language => {
    console.log(language)
    const { botId, adminUser } = this.props
    return adminUser?.botLanguages[botId].some(bl => bl.shortName === language)
  }

  handleReceiveMessages = msg => {
    const { adminUser, botId } = this.props
    const body = JSON.parse(msg.body)

    if (body.action) {
      const message = {
        nativeMessage: JSON.stringify(body),
        lastEventAt: moment().utc().format('YYYY-MM-DD[T]HH:mm'),
        id: uuidv4(),
      }
      saveMessage(message)
    } else if (!_.isObject(body.status)) {
      this.websocketClient.sendStatus(DELIVERED, body, botId)
      //if (this.supportedLanguage(body.languageCode)) {
        this.sendNewMessageBrowserNotification(body)
        updateSupportUserList(body, adminUser.id)
      //}
      if (Number(getUrlEnd()) === body?.userId) {
        saveMessage(body)
      }
    } else {
      updateSupportMessageStatus(body)
    }
  }

  handleReceiveChatStatuses = chatStatus => {
    const { botId, adminUser } = this.props
    const user = JSON.parse(chatStatus.body)
    if (user.isNewRequest || user.supportRequest?.status === SUPPORT_STATUSES.CLOSED) {
      getSupportRequests(botId, adminUser?.id).then()
    }
    const copyUser = Object.assign({}, user)
    updateSupportUserList(copyUser, adminUser.id)

    if (Number(getUrlEnd()) === copyUser?.userId) updateActiveUser(copyUser)
    this.sendAssignedAdminBrowserNotification(user)
  }

  handleReceiveUnassignedCount = unassignedCount => {
    const body = JSON.parse(unassignedCount.body)
    saveUnassignedCount(body.count)
  }

  handleReceiveUnreadAssignedToCount = unassignedCount => {
    const body = JSON.parse(unassignedCount.body)
    saveUnreadAssignedToCount(body.count)
  }

  sendAssignedAdminBrowserNotification = user => {
    const { botId, adminUser } = this.props
    if ((user?.chatStatus === CHAT_STATUS.ACTIVE || user?.chatStatus === CHAT_STATUS.SUPPORT) &&
      user?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED &&
      user?.supportRequest?.adminId === adminUser.id &&
      (document.hidden || !isSupportTab())) {
      NotificationService.displayNotification('New chat has been assigned to you', '', {
        userId: user.userId,
        botId,
      })
    }
  }

  sendNewMessageBrowserNotification = body => {
    const { botId, adminUser } = this.props
    const isCurrentAdmin = body?.supportRequest?.adminId === adminUser.id
    const isAssignedStatus = body?.supportRequest?.status === SUPPORT_STATUSES.ASSIGNED
    const firstCondition = !body.postback && isOpenBot() && isCurrentAdmin && isAssignedStatus
    const secondCondition = document.hidden || !isSupportTab()

    if (body.messagePreview) {
      const message = JSON.parse(body.messagePreview)

      if (firstCondition && secondCondition && message.isFromUser) {
        const title = `Message from: ${body.firstName || ''} ${body.lastName || ''}`
        NotificationService.displayNotification(title, message.text, {
          userId: body.userId,
          botId,
        })
      }
    }
  }

  render() {
    return (
      <SharedWebsocketContext.Provider
        value={{
          sendMessage: message => this.websocketClient.sendMessage(message),
          sendStatus: (status, body) => this.websocketClient.sendStatus(status, body, this.props.botId),
        }}>
        {this.props.children}
      </SharedWebsocketContext.Provider>
    )
  }
}

export default WebSockets

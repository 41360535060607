export const styles = theme => ({
  root: {
    display: 'flex',
    position: 'relative',
  },
  button: {
    padding: 2,
    margin: '0',
    fontSize: 16,
    fontWeight: 700,
    textTransform: 'none',
    fontFamily: "'Lato', sans-serif",
    color: '#354052',
    minHeight: 0,
    minWidth: 0,
  },
  paper: {
    marginRight: 0,
    zIndex: 15,
  },
  popper: {
    right: 'calc(100% - 30px) !important',
    left: 'auto !important',
    top: '100% !important',
    zIndex: 5,
  },
  list: {
    padding: 0,
  },
  listItem: {
    padding: '8px 12px',
    minWidth: 160,
    '& a': {
      color: '#354052',
      fontSize: 14,
      lineHeight: '20px',

      '&:hover': {
        textDecoration: 'none',
      },
    },
    '& button': {
      color: '#354052',
      fontSize: 14,
      lineHeight: '20px',
      border: 'none',
      background: 'transparent',
      textAlign: 'left',
      padding: 0,
    },
  },
  editBtn: {
    color: '#354052',
    fontSize: 14,
    '& svg': {
      marginRight: 10,
    },
  },
})

import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import Heading from 'uiKit/texts/Heading'
import WebsiteURLForm from './WebsiteURLForm'
import SubmitButton from 'uiKit/buttons/SubmitButton'
import LoaderScreen from 'uiKit/loaders/loaderScreen'
import WebsiteURL from './WebsiteURL'
import TrainingStatus from './TrainingStatus'

import { getWebsiteURLs } from '../../api/websiteKnowledgeBase'
import { updateWebsiteURL } from '../../api/websiteKnowledgeBase'
import { alertError } from 'api'

import * as S from './KnowledgeBase.style'
import { CREATING, PENDING } from '../../constants/trainingStatus'

interface Props {
  activeBot: any
  match: any
}

const KnowledgeBase: React.FC<Props> = ({ activeBot }) => {
  const [urls, setUrls] = useState([])
  const [urlsCopy, setUrlsCopy] = useState([])
  const [trainingStatus, setTrainingStatus] = useState('NOT_EXISTS')
  const [loader, setLoader] = useState(true)
  const isUrlsChanged = JSON.stringify(urls) === JSON.stringify(urlsCopy)

  useEffect(() => {
    getWebsiteURLs(activeBot.id)
      .then(res => {
        setUrls(res?.urls || [])
        setUrlsCopy(res?.urls || [])
        setTrainingStatus(res?.training_status)
      })
      .catch(error => alertError(error.message))
      .finally(() => setLoader(false))
  }, [])

  const handleSubmit = () => {
    updateWebsiteURL(activeBot.id, urls)
      .then(res => {
        setUrls(res?.urls || [])
        setUrlsCopy(res?.urls || [])
        setTrainingStatus(res?.training_status)
      })
      .catch(error => alertError(error.message))
      .finally(() => setLoader(false))
  }

  const handleDelete = url => {
    const urlsCopy = urls.filter(x => x !== url)
    setUrls(urlsCopy)
  }

  const handleAdd = url => {
    const websitesCopy = [url, ...urls]
    setUrls(websitesCopy)
  }

  return (
    <S.Container>
      {loader ? (
        <LoaderScreen />
      ) : (
        <>
          <S.Wrap>
            <S.StatusWrap>
              <Heading>Website knowledge base</Heading>
              {!!urls?.length && isUrlsChanged && <TrainingStatus status={trainingStatus} />}
            </S.StatusWrap>
            <SubmitButton
              title="Save"
              disabled={loader || [PENDING, CREATING].includes(trainingStatus) || isUrlsChanged}
              onClick={handleSubmit}
            />
          </S.Wrap>
          <S.FormWrap>
            <WebsiteURLForm onClick={handleAdd} urls={urls} />
            {urls?.map((url, index) => (
              <WebsiteURL url={url} key={url} index={index + 1} onDelete={handleDelete} />
            ))}
            {[PENDING, CREATING].includes(trainingStatus) && <S.DisableScreen />}
          </S.FormWrap>
        </>
      )}
    </S.Container>
  )
}

const mapStateToProps = (state: { activeBot: any }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(KnowledgeBase)

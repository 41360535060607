import api from 'services/api'
import { BASE_URL } from 'configs'

export const getWebsiteURLs = (botId: number) => {
  return api.get(`${BASE_URL}/bot/${botId}/knowledge`)
}

export const updateWebsiteURL = (botId: number, urls: string[]) => {
  return api.put(`${BASE_URL}/bot/${botId}/knowledge`, { urls })
}

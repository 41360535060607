import { OpenRequests } from './OpenRequests'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

const mapStateToProps = state => ({
  assignedToMeUsers: state.supportUsersNew.assignedToMeUsers,
  newRequestsUsers: state.supportUsersNew.newRequestsUsers,
  expiredRequestsUsers: state.supportUsersNew.expiredRequestsUsers,
  assignedRequestsUsers: state.supportUsersNew.assignedRequestsUsers,
  allUsers: state.supportUsersNew.allUsers,
  adminUserId: state.adminUser.id,
  assignedToMeTotal: state.supportRequests?.assignedToMeChats,
  assignedTotal: state.supportRequests?.assignedChats,
  expiredTotal: state.supportRequests?.expiredRequestChats,
  botChatsTotal: state.supportRequests?.botChats,
  newRequestsTotal: state.supportRequests?.newRequestChats,
})

export default withRouter(connect(mapStateToProps)(OpenRequests))

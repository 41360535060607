import React from 'react'

import * as S from './WebsiteURL.style'

interface Props {
  url: string
  index: number
  onDelete: (url: string) => void
}

export const WebsiteURL: React.FC<Props> = ({ url, index, onDelete }) => {
  return (
    <S.Container index={index}>
      <S.Text>{url}</S.Text>
      <S.DeleteButton onClick={() => onDelete(url)}>Delete</S.DeleteButton>
    </S.Container>
  )
}

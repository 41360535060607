import { BASE_URL } from '../../../configs'
import {
  alertError,
  alertSuccess,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../api'
import { saveBotAccessors } from '../actions/botAccessors'
import api from '../../../services/api'
import { getBot } from '../../home/api/bots'

export function saveTeamHours(id, settings) {
  fetch(BASE_URL + '/bot/' + id + '/workingHours', {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(settings),
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(function(error) {
      alertError('Something goes wrong. Ping administrator.')
    })
}

export function loadBotAccessors(botId) {
  fetch(BASE_URL + '/botAccesses/' + botId, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(json => {
      saveBotAccessors(json)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function postBotAccessor(botId, body) {
    return fetch(`${BASE_URL}/botAccesses/${botId}/extended`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'POST',
        body: JSON.stringify(body),
    })
        .then(() => {
            alertSuccess('User updated successfully!')
        })
        .catch(function(error) {
            alertError('Fail. Try it later')
        })
}

export function updateBotAccessorRole(botId, accessorId, inviteAccessId, role) {
  const url =
    accessorId === null
      ? `${BASE_URL}/botAccesses/${botId}/pending-user/${inviteAccessId}?role=${role}`
      : `${BASE_URL}/botAccesses/${botId}/${accessorId}?role=${role}`

  fetch(url, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PATCH',
  })
    .then(response => {
      return response
    })
    .then(() => {
      loadBotAccessors(botId)
      alertSuccess("User's settings has been successfully updated.", 3000)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export const updateSalesforceId = (botId, email, id) => {
    fetch(`${BASE_URL}/botAccesses/${botId}/extended/set-salesforce-id?email=${email}&salesforceId=${id}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'POST',
    })
        .then(response => {
            return response
        })
        .then(() => {
            loadBotAccessors(botId)
            alertSuccess("User's settings has been successfully updated.", 3000)
        })
        .catch(function(error) {
            alertError('Fail. Try it later')
        })
}

export const deleteSalesforceId = (botId, email, id) => {
    fetch(`${BASE_URL}/botAccesses/${botId}/extended/delete-salesforce-id?email=${email}`, {
        credentials: 'same-origin',
        headers: createJsonHeaders(),
        method: 'DELETE',
    })
        .then(response => {
            return response
        })
        .then(() => {
            loadBotAccessors(botId)
            alertSuccess("User's settings has been successfully updated.", 3000)
        })
        .catch(function(error) {
            alertError('Fail. Try it later')
        })
}

export function updateBotAccessorSupportRequestNotifying(
  botId,
  accessorId,
  supportRequestNotify,
) {
  fetch(
    BASE_URL +
      '/botAccesses/' +
      botId +
      '/' +
      accessorId +
      '?supportRequestNotify=' +
      supportRequestNotify,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'PATCH',
    },
  )
    .then(response => {
      return response
    })
    .then(() => {
      loadBotAccessors(botId)
      alertSuccess("User's settings has been successfully updated.", 3000)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function removeBotAccessor(botId, accessorId, inviteAccessId) {
  const url =
    accessorId == null
      ? `${BASE_URL}/botAccesses/${botId}/pending-user/${inviteAccessId}`
      : `${BASE_URL}/botAccesses/${botId}/${accessorId}`

  fetch(url, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'DELETE',
  })
    .then(response => {
      return response
    })
    .then(() => {
      loadBotAccessors(botId)
    })
    .catch(function(error) {
      alertError('Fail. Try it later')
    })
}

export function getBotLanguages(botId) {
  return fetch(`${BASE_URL}/bot/${botId}/language/info`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(botLanguages => {
      const { defaultLanguage, activeLanguages } = botLanguages
      let index = -1
      activeLanguages.map(language => {
        if (language.id === defaultLanguage.id)
          index = activeLanguages.indexOf(language)
      })
      if (index !== -1) activeLanguages.splice(index, 1)
      defaultLanguage.isDefault = true
      activeLanguages.unshift(defaultLanguage)

      return botLanguages
    })
    .catch(error => console.log(error))
}

//TODO
export function addLanguage(botId, languageCode) {
  return api.post(`${BASE_URL}/bot/${botId}/language?languageCode=${languageCode}`)
    .then(() => alertSuccess('Language added successfully', 3000))
}

export function deleteLanguage(botId, languageCode) {
  return fetch(
    `${BASE_URL}/bot/${botId}/language?languageCode=${languageCode}`,
    {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'DELETE',
    },
  )
    .then(response => {
      if (response.ok) {
        alertSuccess('Language deleted', 3000)
      } else {
        alertError(
          'Sorry but something going wrong at getting intents please ping support!',
        )
      }
      return response
    })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(error => console.log(error))
}

export function toggleContextualConversations(botId, enabled) {
  api.put(`${BASE_URL}/bot/${botId}/contextualizer?enable=${enabled}`)
    .then(() => getBot(botId))
    .then(() => alertSuccess('Bot is updated successfully.', 3000))
}

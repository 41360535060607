import React from 'react'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import { FormControlLabel } from '@material-ui/core'
import { Controller } from 'react-hook-form/dist/index.ie11'

import { ROLE } from '../../../../../security'
import InputHolder from '../../../../../uiKit/inputs/InputHolder'

export const EditUserAdditional = props => {
  const { classes } = props

  return (
    <div>
      <p className={classes.mainText}>Fill in user information that will be invited to the platform</p>
      <div className={classes.item}>
        <p className={classes.itemLabel}>Full name</p>
        <p className={classes.itemField}>{props.user.name}</p>
      </div>
      <div className={classes.item}>
        <p className={classes.itemLabel}>Email</p>
        <p className={classes.itemField}>{props.user.email}</p>
      </div>
      <div className={classes.item}>
        <p style={{ marginBottom: 8 }} className={classes.itemLabel}>
          User role
        </p>
        <Controller
          name="role"
          control={props.control}
          defaultValue={props.user.role}
          render={({ value, onChange }) => (
            <RadioGroup className={classes.group} value={value} onChange={onChange}>
              <FormControlLabel
                value={ROLE.ADMIN}
                classes={{ root: classes.radioRoot }}
                control={<Radio color="primary" />}
                label="Admin"
              />
              <FormControlLabel
                value={ROLE.SUPPORT}
                classes={{ root: classes.radioRoot }}
                control={<Radio color="primary" />}
                label="Support"
              />
            </RadioGroup>
          )}
        />
      </div>
      <div className={classes.item}>
        <p className={classes.itemLabel}>Salesforce ID</p>
        <Controller
          name="salesforceId"
          control={props.control}
          value={props.user.salesforceId}
          render={({ onChange, value }) => (
            <InputHolder
              inputStyle={{ width: '100%', padding: 0 }}
              holderStyle={{ width: '100%', padding: '8px 12px', borderRadius: 4 }}
              defaultValue={value}
              placeholder={'Paste Salesforce ID here...'}
              onChange={onChange}
              maxLength={30}
            />
          )}
        />
      </div>
    </div>
  )
}

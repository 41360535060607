import React, {useRef, useState} from 'react'
import {Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, withStyles} from '@material-ui/core'

import {DownArrowIcon} from '../../../../uiKit/icons/Icons'
import {setMessageRead, setMessageUnread} from '../../api/user'
import {updateUserReadMessages, updateUserUnreadMessages} from '../../actions/users'
import PropTypes from 'prop-types'
import {styles} from './styles'

export const UserNameMenu = props => {
  const {classes, user} = props
  const [open, setOpen] = useState(false)
  const anchorRef = useRef(null)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChatUnread = () => {
    setMessageUnread(user?.chatId)
      .then(() => updateUserReadMessages(user))
      .then(() => handleClose())
  }

  const handleChatRead = () => {
    setMessageRead(user?.chatId)
      .then(() => updateUserUnreadMessages(user))
      .then(() => handleClose())
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    prevOpen.current = open
  }, [open])

  return (
    <div className={classes.root}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        className={classes.button}>
        {user.firstName} {user.lastName}
        <span style={{transform: open && 'rotate(180deg)', marginLeft: '8px'}}>
          <DownArrowIcon/>
        </span>
      </Button>
      <Popper
        transition
        disablePortal
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        style={{zIndex: '1'}}
        className={classes.popper}>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
            <Paper className={classes.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.list}>
                  {!user?.unreadMessage && (
                    <MenuItem onClick={handleChatUnread} className={classes.listItem}>
                      <button>Mark as unread</button>
                    </MenuItem>
                  )}
                  {user?.unreadMessage && (
                    <MenuItem onClick={handleChatRead} className={classes.listItem}>
                      <button>Mark as read</button>
                    </MenuItem>
                  )}
                  {user?.linkToSforceCase && (
                    <MenuItem onClick={handleClose} className={classes.listItem}>
                      <a href={user?.linkToSforceCase} target="_blank" rel="noreferrer">
                                                View Salesforce case
                      </a>
                    </MenuItem>)}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  )
}

UserNameMenu.propTypes = {
  classes: PropTypes.object,
}

export default withStyles(styles)(UserNameMenu)

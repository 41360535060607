import General from '../tabs/settings/components/general/General/General'
import Hours from '../tabs/settings/components/hours/Hours'
import NewPersistentMenu from '../tabs/settings/components/persistentMenu/PersistentMenu'
import Integrations from '../tabs/settings/components/integrations/NewIntegrations'
import Users from '../tabs/settings/components/users/Users'
import Languages from '../tabs/settings/components/languages/Languages'
import KnowledgeBase from '../tabs/settings/components/knowledgeBase/KnowledgeBase'

export const settingsRoutes = [
  {
    path: 'general',
    name: 'General',
    component: General,
  },
  {
    path: 'languages',
    name: 'Languages',
    component: Languages,
  },
  {
    path: 'hours',
    name: 'Team Hours',
    component: Hours,
  },
  {
    path: 'users',
    name: 'Users',
    component: Users,
  },
  {
    path: 'integrations',
    name: 'Integrations',
    component: Integrations,
  },
  {
    path: 'persistent',
    name: 'Main Menu',
    component: NewPersistentMenu,
  },
  {
    path: 'knowledgeBase',
    name: 'Website knowledge base',
    component: KnowledgeBase,
  },
]

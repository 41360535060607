export const styles = () => ({
  styleCheckbox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > p': {
      margin: 0,
      fontSize: 14,
      lineHeight: '20px',
      color: '#354052',
    },
  },
  styleParagraph: {
    margin: 0,
    fontSize: 14,
    lineHeight: '20px',
    color: '#354052',
  },
  styleError: {
    position: 'absolute',
    color: '#FF6666',
  },
  styleMainText: {
    color: 'rgba(53, 64, 82, 0.7)',
  },
  switchBase: {
    height: 34,
  },
})

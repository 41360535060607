export const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textCheckbox: {
    margin: 0,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#354052',

    '& span': {
      display: 'block',
      fontSize: '12px',
      lineHeight: '16px',
      color: 'rgba(53, 64, 82, 0.7)',
      marginTop: 4,
    },
  },
})

import { BASE_URL, BACKEND_URL } from 'configs'
import { alertError, createJsonHeaders, logoutOnRequestOrResponseJson, customResponseParser } from 'api'
import { AGENT_JOINED, AGENT_LEFT } from 'constants/widgetActionsType'

export function getMessages(chatId, page, botId) {
  const params = new URLSearchParams({
    page,
    size: '20',
    sort: 'id,desc',
  })

  return fetch(`${BASE_URL}/bot/${botId}/support/user/${chatId}/chat?${params}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function closeUserRequest(botId, json, chatId, closeSforceCase) {
  return fetch(`${BASE_URL}/${botId}/extended/support/back-to-bot?closeCase=${closeSforceCase}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  }).then(() => sendAction(AGENT_LEFT, botId, chatId))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function assignSupportRequest(botId, json, chatId) {
  return fetch(`${BASE_URL}/bot/${botId}/support/assign`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'POST',
    body: JSON.stringify(json),
  })
    .then(() => sendAction(AGENT_JOINED, botId, chatId))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function getAdminsList(botId, userId) {
  return fetch(`${BASE_URL}/extended/admin/${botId}/all?userId=${userId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'GET',
  })
    .then(response => logoutOnRequestOrResponseJson(response))
    .then(response => customResponseParser(response))
    .catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function setMessageRead(chatId) {
  return fetch(`${BASE_URL}/support/read/user/${chatId}`, {
    credentials: 'include',
    headers: createJsonHeaders(),
    method: 'PUT',
  }).catch(() => alertError('Sorry but something is going wrong please ping support!', 5000))
}

export function sendAction(actionType, botId, chatId) {
  const event = {
    action: {
      type: actionType,
    },
    chatId: chatId?.toString()
  }
  if (chatId) {
    return fetch(`${BACKEND_URL}/widget/${botId}/chat/${chatId}/action`, {
      headers: createJsonHeaders(),
      method: 'POST',
      body: JSON.stringify(event),
    })
  }
}

export const setMessageUnread = chatId =>
    fetch(`${BASE_URL}/support/unread/user/${chatId}`, {
      credentials: 'include',
      headers: createJsonHeaders(),
      method: 'PUT',
    }).catch(function(error) {
      alertError(`Sorry but something going wrong please ping support! ${error}`)
    })

import { store } from '../../../index'

export const SAVE_UNASSIGNED_COUNT = 'SAVE_UNASSIGNED_COUNT'
export const SAVE_UNREAD_ASSIGNED_TO_COUNT = 'SAVE_UNREAD_ASSIGNED_TO_COUNT'
export const SAVE_SUPPORT_REQUESTS = 'SAVE_SUPPORT_REQUESTS'

export const saveUnassignedCount = requests =>
  store.dispatch({
    type: SAVE_UNASSIGNED_COUNT,
    requests,
  })

export const saveUnreadAssignedToCount = requests =>
  store.dispatch({
    type: SAVE_UNREAD_ASSIGNED_TO_COUNT,
    requests,
  })

export const saveSupportRequests = requests =>
    store.dispatch({
        type: SAVE_SUPPORT_REQUESTS,
        requests,
    })

import {SAVE_SUPPORT_REQUESTS, SAVE_UNASSIGNED_COUNT, SAVE_UNREAD_ASSIGNED_TO_COUNT} from '../actions/supportRequest'

const initialState = {
    unassignedCount: 0,
    unreadAssignedToCount: 0
}

const supportRequests = (state = initialState, {type, requests}) => {
    switch (type) {
        case SAVE_UNASSIGNED_COUNT:
            return {
                ...state,
                unassignedCount: requests
            }
        case SAVE_UNREAD_ASSIGNED_TO_COUNT:
            return {
                ...state,
                unreadAssignedToCount: requests
            }
        case SAVE_SUPPORT_REQUESTS:
            return requests
        default:
            return state
    }
}

export default supportRequests

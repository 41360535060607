import React from 'react'
import { Controller } from 'react-hook-form/dist/index.ie11'
import Switch from '@material-ui/core/Switch'

export const EditUserGeneral = props => {
  const { classes } = props

  return (
    <div className={classes.container}>
      <p className={classes.textCheckbox}>
        Send notifications
        <span>Send email notification about new customer support request and assigned to you request.</span>
      </p>
      <Controller
        name="supportRequestNotify"
        control={props.control}
        render={({ value, onChange }) => (
          <Switch size="small" checked={!!value} onChange={e => onChange(e.target.checked)} color="primary" />
        )}
      />
    </div>
  )
}

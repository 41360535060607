export const styles = () => ({
  mainText: {
    margin: 0,
    color: 'rgba(53, 64, 82, 0.7)',
  },
  item: {
    marginTop: 22,
  },
  itemLabel: {
    marginBottom: 2,
  },
  itemField: {
    color: 'rgba(53, 64, 82, 0.7)',
    margin: 0,
  },
  radioRoot: {
    height: 30,
    width: 30,
    color: '#354052',
    '& input': {
      height: 20,
      width: 20,
    },
  },
})
